import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Image, Row, Col } from "react-bootstrap"

import Image01 from "../../../images/koenigsschiessen/2013/01.jpg"
import Image02 from "../../../images/koenigsschiessen/2013/02.jpg"
import Image03 from "../../../images/koenigsschiessen/2013/03.jpg"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Gallery from "../../../components/gallery"

const Koenigsschiessen2013 = props => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          relativePath: { regex: "/koenigsschiessen/2013/gallery_.*.jpg/" }
        }
      ) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fixed(width: 500) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Königsschießen 2013" lang="de" />
      <Container>
        <h1 className="h3">Königsschießen 2013</h1>
        <hr className="featurette-divider" />
        <Row>
          <Col xs={6}>
            <Image src={Image01} fluid={true} className="d-block" />
          </Col>
          <Col xs={6}>
            <p>
              Die »Königsfamilie« 2013: Heiko Lippert (2. Ritter), Wolfgang
              Grieb (1. Ritter), Friedhelm Schwenz (Schützenkönig), Denise
              Döring (Schützenkönigin), Nicole Beppler-Alt (2. Edelfräulein),
              Katharina Beppler-Alt (1. Edelfräulein)
            </p>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={6}>
            <Image src={Image02} fluid={true} className="d-block" />
          </Col>
          <Col xs={6}>
            <p>Die »Königsketten« des Schützenverein Gambach</p>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={6}>
            <Image src={Image03} fluid={true} className="d-block" />
          </Col>
          <Col xs={6}>
            <p>
              Unsere Kreismeister 2013: Heiko Lippert (KK-100-Meter), Michael
              Rheinfrank (KK-Sportgewehr)
            </p>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={6}>
            <Gallery images={data.allFile.edges} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Koenigsschiessen2013
